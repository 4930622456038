import React from 'react';
import NextLink from 'next/link';
import { Stack } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import Notice404Image from '@aph/images/error-404.svg';
import PillImage from '@aph/images/get-prescription.svg';
import ShopOnlineImage from '@aph/images/shop-online.svg';
import TipsImage from '@aph/images/tips-and-recommendations.svg';
import { Typography } from '@aph/ui/components/typography/typography';

export const Notice404 = () => {
  const intl = useIntl();

  return (
    <>
      <Stack alignItems="center" gap={1}>
        <Notice404Image />
        <Typography typography="headingExtraLarge" color="text-brand">
          <FormattedMessage id="ERROR.HEADING" />
        </Typography>

        <Typography>
          <FormattedMessage id="ERROR.TEXT" />
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-around" marginTop={4}>
        <Stack
          component={NextLink}
          href="/"
          title={intl.formatMessage({ id: 'ERROR.LINKS.SHOP_ONLINE' })}
          alignItems="center"
        >
          <ShopOnlineImage />
          <Typography className="font-bold">
            <FormattedMessage id="ERROR.LINKS.SHOP_ONLINE" />
          </Typography>
        </Stack>

        <Stack
          component={NextLink}
          href="/mina-recept/receptlistan/"
          title={intl.formatMessage({ id: 'ERROR.LINKS.GET_PRESCRIPTION' })}
          alignItems="center"
        >
          <PillImage />
          <FormattedMessage id="ERROR.LINKS.GET_PRESCRIPTION" />
        </Stack>

        <Stack
          component={NextLink}
          href="/tips-och-rad/"
          title={intl.formatMessage({ id: 'ERROR.LINKS.GET_TIP' })}
          alignItems="center"
        >
          <TipsImage />
          <FormattedMessage id="ERROR.LINKS.GET_TIP" />
        </Stack>
      </Stack>
    </>
  );
};
