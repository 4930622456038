import { Box } from '@mui/material';
import { sendEcommerce } from '@aph/components/gtm/useGtm';
import type { IStartpageFields } from '~/contentful/types';
import { ImageLink, type ImageLinkProps } from '../image-link/image-link';

type HeroCampaignProps = {
  heroCampaign: IStartpageFields['heroCampaign'];
};

const getTrackingData = (heroCampaign: IStartpageFields['heroCampaign']) => ({
  creative_name: heroCampaign.fields.title,
  creative_slot: 'hero_campaign',
  promotion_id: heroCampaign.sys.id,
  promotion_name: 'Startpage hero kampanj',
});

export const HeroCampaign = ({ heroCampaign }: HeroCampaignProps) => {
  const { link, image, imageMobile } = heroCampaign.fields;

  const baseProps: Pick<ImageLinkProps, 'onInView' | 'href' | 'onClick'> = {
    href: link,
    onInView: () => {
      sendEcommerce({
        event: 'view_promotion',
        ecommerce: getTrackingData(heroCampaign),
      });
    },
    onClick: () => {
      sendEcommerce({
        event: 'select_promotion',
        ecommerce: getTrackingData(heroCampaign),
      });
    },
  };

  return (
    <Box>
      {imageMobile && (
        <Box height="100%" width="100%" display={{ xs: 'inline', md: 'none' }}>
          <ImageLink
            {...baseProps}
            sizes="100vw"
            priority
            src={imageMobile}
            alt={imageMobile.fields.description || imageMobile.fields.title}
            height={imageMobile.fields.file.details?.image?.height}
            width={imageMobile.fields.file.details?.image?.width}
          />
        </Box>
      )}
      <Box height="100%" width="100%" display={{ xs: 'none', md: 'inline' }}>
        <ImageLink
          {...baseProps}
          sizes="33vw"
          priority
          src={image}
          alt={image.fields.description || image.fields.title}
          height={image.fields.file.details.image?.height}
          width={image.fields.file.details.image?.width}
        />
      </Box>
    </Box>
  );
};
