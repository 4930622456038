import type { Asset } from 'contentful';
import type { NextSeoProps } from 'next-seo';
import type { ISeoIncludeFields } from '../types';

const getOgImage = (asset?: Asset) => {
  if (!asset?.fields.file.url) {
    return undefined;
  }
  return {
    url: asset?.fields.file.url,
    ...(asset?.fields.file.details.image?.height && {
      height: asset?.fields.file.details.image?.height,
    }),
    ...(asset?.fields.file.details.image?.width && {
      width: asset?.fields.file.details.image?.width,
    }),
  };
};

export const mapContentfulSEOToNextSEO = ({
  canonicalUrl,
  ogLocale,
  ogImage,
  ogType,
  title,
  description,
  noFollow,
  noIndex,
}: ISeoIncludeFields = {}): NextSeoProps => {
  const openGraphImg = getOgImage(ogImage?.fields.image);

  return {
    title,
    noindex: noIndex,
    nofollow: noFollow,
    description,
    canonical: canonicalUrl,
    openGraph: {
      ...(openGraphImg && { images: [openGraphImg] }),
      locale: ogLocale,
      type: ogType,
    },
  };
};
