import 'reflect-metadata';
import React from 'react';
import type { NextPage, NextPageContext } from 'next';
import { Notice404 } from '@aph/components/error/notice-404/notice-404.component';
import { PageLayout } from '@aph/components/layouts/page-layout.component';
import { Typography } from '@aph/ui/components/typography/typography';
import { logger } from '~/logging';

export type ErrorPageProps = {
  statusCode: number;
  err?:
  | (Error & {
    statusCode?: number | undefined;
  })
  | null
  | undefined;
};

const NextErrorPage: NextPage<ErrorPageProps> = ({ statusCode, err }) => {
  return (
    <PageLayout
      wave={false}
      seo={{
        noindex: true,
        title: 'Error Page',
        description: 'ErrorPage page description',
      }}
    >
      {statusCode === 404 ? (
        <Notice404 />
      ) : (
        <Typography>An error {statusCode} occurred on server</Typography>
      )}
      {process.env.NODE_ENV !== 'production' && <pre>{JSON.stringify(err)}</pre>}
    </PageLayout>
  );
};

NextErrorPage.getInitialProps = async ({ res, err }: NextPageContext) => {
  logger.warn(
    { err },
    `Error page was shown. This is getInitialProps err ${res?.statusCode || 'no status code available'
    } `,
  );
  const statusCode = res?.statusCode || err?.statusCode || 404;
  return { statusCode, err };
};

export default NextErrorPage;
