import React from 'react';
import { Skeleton } from '@mui/material';
import { LinkList } from '@aph/components/common/link-list/link-list.component';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { useGetPromotedLinks } from '../../components/common/link-list/use-get-promoted-links';
import { ArticleCategoryNavigation } from './article-category-navigation/article-category-navigation.component';

export const SidebarWithCategoryNavigation = () => {
  const { data: PROMOTED_LINKS, error } = useGetPromotedLinks();

  let promotedLinksComponent: React.ReactNode = (
    <div className="flex flex-col gap-y-2">
      <Skeleton variant="text" height={22} />
      <Skeleton variant="text" height={22} />
      <Skeleton variant="text" height={22} />
    </div>
  );

  if (PROMOTED_LINKS) {
    promotedLinksComponent = (
      <LinkList
        links={PROMOTED_LINKS}
        variant="primary"
        onLinkClick={(target) => sendMainNavigationEvent(parseEventTree(target.href))}
      />
    );
  }

  if (error) {
    promotedLinksComponent = null;
  }

  return (
    <nav className="flex flex-col gap-y-3">
      {promotedLinksComponent}
      <ArticleCategoryNavigation />
    </nav>
  );
};
