import { useRef } from 'react';
import { sendEcommerce } from '@aph/components/gtm/useGtm';
import { Banner } from '~/contentful/components/banner/banner';
import type { IImageLink, IStartpageFields } from '~/contentful/types';

const getTrackingData = (image: IImageLink, index = 0) => ({
  creative_name: image.fields.title,
  creative_slot: `hero_slideshow_${index}`,
  promotion_id: image.sys.id,
  promotion_name: 'Startpage hero banner',
});

export const HeroBanner = ({ heroBanner }: Pick<IStartpageFields, 'heroBanner'>) => {
  const shownIndexes = useRef<number[]>([]);

  return (
    <Banner
      imageLinks={heroBanner}
      slideIntervalInSeconds={5}
      onChange={(image, index) => {
        if (!shownIndexes.current.includes(index)) {
          shownIndexes.current = [...shownIndexes.current, index];
          sendEcommerce({
            event: 'view_promotion',
            ecommerce: getTrackingData(image, index),
          });
        }
      }}
      onClick={(image, index) => {
        sendEcommerce({
          event: 'select_promotion',
          ecommerce: getTrackingData(image, index),
        });
      }}
    />
  );
};
