import NextErrorPage from '~/pages/_error';

interface PageErrorHandlerProps {
  error?: {
    status: number;
  };
}

export function withPageErrorHandler<TComponentProps>(Component: React.FC<TComponentProps>) {
  const WrappedComponent = ({ error, ...props }: TComponentProps & PageErrorHandlerProps) => {
    if (error) {
      return <NextErrorPage statusCode={error.status} />;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - this is a hack to get the typescript to work
    return <Component {...props} />;
  };

  return WrappedComponent;
}
