import { Stack } from '@mui/material';
import {
  type UseGetPageArticleGroupsProps,
  useGetPageArticleGroups,
} from '../../hooks/use-get-page-article-groups/use-get-page-article-groups';
import { ArticleCarousel } from '../article-carousel/article-carousel';

export const ArticleGroups = ({ queryBy }: { queryBy: UseGetPageArticleGroupsProps }) => {
  const { data: groups = [], isLoading } = useGetPageArticleGroups(queryBy);
  return (
    <Stack gap={6}>
      {isLoading ? (
        <ArticleCarousel isLoading />
      ) : (
        groups.map(({ articles = [], title = '' }) => (
          <ArticleCarousel key={title} title={title} articles={articles} />
        ))
      )}
    </Stack>
  );
};
