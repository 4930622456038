import { useRouter } from 'next/router';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import type { NextSeoProps } from 'next-seo';

type GetSEOPropsWithFallbacksProps = {
  pageTitle: string;
  seoProps: NextSeoProps;
  customFallbacks?: Partial<NextSeoProps>;
};

const formatUrl = (slug: string) => {
  const url = new URL(`${process.env.NEXT_PUBLIC_BASE_URL_WEB}${slug}`);

  url.searchParams.forEach((value, key) => {
    if (key !== 'page' || (key === 'page' && value === '1')) {
      url.searchParams.delete(key);
    }
  });

  return url.toString();
};

export const useGetSEOPropsWithFallbacks = ({
  pageTitle,
  seoProps,
  customFallbacks,
}: GetSEOPropsWithFallbacksProps): NextSeoProps => {
  const { asPath: slugWithParams } = useRouter();
  const fallbackData = {
    title: `${pageTitle} - Apotek Hjärtat`,
    description: `${pageTitle} se vårt stora utbud. Handla snabbt & enkelt online eller från ett Apotek Hjärtat nära dig`,
    canonical: formatUrl(slugWithParams),
  };

  return {
    ...fallbackData,
    ...pickBy(customFallbacks, identity),
    ...pickBy(seoProps, identity),
  };
};
